import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import mermaid from 'mermaid'
import { PanZoom } from 'react-easy-panzoom'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet';
import { CircularProgress } from '@mui/material'

const FlowChartDiagram = ({
  direction,
  panZoomRef,
  setOpenSnackbar,
  setSnackbarMessage, setSnackbarSeverity,
  setOpenSnackbarDiagram,
}) => {
  let code = useSelector((state) => state.editor.editorValue)
    const [flowchartImage, setFlowchartImage] = useState('')
  const [loading, setLoading] = useState(false)
  
  const flowchartCompiler = (flowchartStr, direction) => {
    var response = 'flowchart ' + direction + '\n'

    const flowchartMap = new Map()
    const flowchartArray = flowchartStr.split('\n')

    for (let str of flowchartArray) {
      str = str.trim()
      if (str.length > 0 && str !== ' ') {
        const innerArray = str.split('-')
        const stringWithoutSpaces = innerArray[0].replace(/\s/g, '').trim()
        if (flowchartMap.size == 0) {
          response += stringWithoutSpaces.trim() + '([' + innerArray[0] + ']):::terminal -->'
          flowchartMap.set(stringWithoutSpaces, 1)
        } else {
          if (flowchartMap.get(stringWithoutSpaces)) {
            response += stringWithoutSpaces.trim() + '{' + innerArray[0] + '}:::decision -->'
          } else {
            response += stringWithoutSpaces.trim() + '[' + innerArray[0] + '] -->'
            flowchartMap.set(stringWithoutSpaces, 1)
          }
        }

        if (innerArray.length > 1) {
          const temp = innerArray[1]
          const secondArr = temp.split(':')

          let stringWithoutSpacesTwo = secondArr[0].replace(/\s/g, '')
          if (secondArr[0].trim() === 'End' || secondArr[0].trim() === 'end') {
            secondArr[0] = 'End'
            stringWithoutSpacesTwo = 'End'
            response += stringWithoutSpacesTwo.trim() + '([' + secondArr[0].trim() + ']):::terminal'
          } else if (secondArr[0].trim() === 'Stop' || secondArr[0].trim() === 'stop') {
            secondArr[0] = 'Stop'
            stringWithoutSpacesTwo = 'Stop'
            response += stringWithoutSpacesTwo.trim() + '([' + secondArr[0].trim() + ']):::terminal'
          } else {
            if (secondArr.length > 1) {
              response +=
                '|' + secondArr[1] + '| ' + stringWithoutSpacesTwo.trim() + '[' + secondArr[0] + ']'
            } else {
              response += stringWithoutSpacesTwo.trim() + '[' + secondArr[0].trim() + ']'
            }
          }

          response += '\n'
        }
      }
    }
    return response + 'classDef terminal fill: pink \n classDef decision fill: lightblue'
  }

  const generateFlowchart = async (flowchart) => {
    setLoading(true)
    setOpenSnackbarDiagram(true)
    try {
      mermaid.initialize({
        theme: 'base',
        themeVariables: {
          primaryColor: '#bccfc7',
          primaryTextColor: '#666666',
          primaryBorderColor: '#183D3D',
          lineColor: '#F8B229',
          edgeLabelBackground: 'transparent',
        },
      })

      var flowData = flowchartCompiler(flowchart, direction)
      flowData = '\n' + flowData
      const result = await mermaid.render('graph', flowData)
      let svgText = result.svg
      let downloadSvgText = result.svg

      if (sessionStorage.getItem('isSubscribed') !== 'true') {
        downloadSvgText = downloadSvgText.replace(
          '</style>',
          `</style>
      <text transform='translate(10, 20)' fill='rgba(45,45,45,0.2)' font-size='20'>code2diagram.com</text>`,
        )
      }

      sessionStorage.setItem('LAST_GENERATED_DIAGRAM', `data:image/svg+xml,${encodeURIComponent(svgText)}`)
      localStorage.setItem('FLOWCHART_IMAGE', `data:image/svg+xml,${encodeURIComponent(svgText)}`)
      sessionStorage.setItem('DOWNLOAD_FLOWCHART_IMAGE', `data:image/svg+xml,${encodeURIComponent(downloadSvgText)}`)
      let res = svgText
      setLoading(false)
      setOpenSnackbarDiagram(false)

            return res
    } catch (error) {

      const maxMessageLength = 100; // Limit to 100 characters

      // Get the error message (with fallback if no message property exists)
      let errorMessage = error.message || error.toString();

      // Limit the error message size
      if (errorMessage.length > maxMessageLength) {
        errorMessage = errorMessage.substring(0, maxMessageLength) + '...'; // Truncate and add ellipsis
      }

      setLoading(false)
      setOpenSnackbar(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity('error')
      setOpenSnackbarDiagram(false)
      return null;
    }
  }

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    const generateFlowchartImage = async () => {
      if (!code || code.trim() === '') {
        setFlowchartImage('')
        setLoading(false)
        return
      }
      try {
        const outputImage = await generateFlowchart(code)
        if(outputImage != null) {
          setLoading(false);
          setFlowchartImage(outputImage);
        } else if(outputImage == null && flowchartImage == '') {
          setLoading(true);
        }
      } catch (error) {
        console.error('Error generating flowchart diagram:', error)
      }
    }
    generateFlowchartImage()
    localStorage.setItem("selectedMode", CONSTANTS.FLOW_CHART);
  }, [code, direction])
  
  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          {loading && (
            <div className="alignMiddle">
          <CircularProgress />
              Initializing the Data...
            </div>
          )}
           {!loading &&( flowchartImage? (
          <img
            src={`data:image/svg+xml,${encodeURIComponent(flowchartImage)}`}
            alt="Flowchart Diagram"
              className="image-style"/>
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
        </PanZoom>
      </MapContainer>
    </div>
  )
}

export default FlowChartDiagram
