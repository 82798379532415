import DeleteIcon from '@mui/icons-material/Delete'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Editor } from '@monaco-editor/react'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Snackbar, Tooltip } from '@mui/material'
import { sidebartoggle } from 'src/redux/actions/sidebarToggle'
import { CONSTANTS } from '../Constants'
import { generateWebSeq } from '../diagrams/WebSequence'
import { setEditorValue } from 'src/redux/actions/editorActions'
import { setClassEditorValue } from 'src/redux/actions/classEditorActions'
import { setGanttEditorValue } from 'src/redux/actions/ganttChartActions'
import {
  setBrainstormEditorValue,
  setD2EditorValue,
} from 'src/redux/actions/brainstormEditorAction'
import { setProtobufEditorValue } from 'src/redux/actions/protobufEditorAction'
import { setAvroSchemaEditorValue } from 'src/redux/actions/avroSchemaAction'
import { setDockerComposeEditorValue } from 'src/redux/actions/dockerComposeEditorAction'
import { setMindmapEditorValue } from 'src/redux/actions/mindmapAction'
import { setArchitectureEditorValue } from 'src/redux/actions/ArchEditorActions'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedMode } from 'src/redux/actions/StateAction'
import AvscModal from '../modals/AvscModal'
import { setArchGoEditorValue } from 'src/redux/actions/ArchGoEditorActions'
import { setTerraformEditorValue } from 'src/redux/actions/terraformActions'
import CustomDropdown from './CustomDropdown'
import { setMathEqEditorValue } from 'src/redux/actions/mathEqAction'
import { setPlaybookGrapherEditorValue } from 'src/redux/actions/playbookGrapherEditorAction'
import { setInventoryGrapherEditorValue } from 'src/redux/actions/inventoryGrapherEditorAction'
import UndoButton from './UndoButton'
import UndoRedoButtons from './UndoButton'
import ChatGPT from './ChatGPT'
import CollaborativeSession from './CollaborativeCoding'

const EditorSide = ({
  monacoRef,
  setVisibleLoading,
  setImageSrc,
  appendCode,
  setSnackbarMessage,
  setOpenSnackbar,
  setSnackbarSeverity,
  setOpenSnackbarDiagram,
  handleOptionChange,
  selectedCode,
  codeByFileName,
  isAuthenticated,
  setVisibleSave,
  saveCodeApi,
  selectedOptionAuto,
  history,
  setShowDeleteConfirmation,
  editorState,
  setPythonError,
  pythonError,
  handleExecute,
  handleUndo, handleRedo,
  isDialogOpen,
  handleClose,
  direction,
  setDirection
}) => {
  const [isIframe, setIsIframe] = useState(false);
  const [visibleArrow, setVisibleArrow] = useState(false)
  const [intellisenseConfig, setIntellisenseConfig] = useState(null)
  let selectedMode = useSelector((state) => state.stateReducer.selectModeValue)
  const dispatch = useDispatch()
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [fileName, setFileName] = useState('No file chosen');
  const [fileSelected, setFileSelected] = useState(false);

  const onChatGPTResponse = (res) => {
    monacoRef.current.setValue(res);
  }

  let chatGptCode = localStorage.getItem("chatGPTCode");
  const [isRefUpdated, setIsRefUpdated] = useState(false);

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    if (chatGptCode && chatGptCode.length > 0 && monacoRef && monacoRef.current) {
      monacoRef.current.setValue(chatGptCode);
      localStorage.setItem("chatGPTCode", "");
    }
  }, [chatGptCode, isRefUpdated])

  async function handleEditorDidMount(editor, monaco) {
    monacoRef.current = editor
    monacoRef.current.setValue("")
    editor.setSelection(new monaco.Selection(1, 23, 1, 23))
    monacoRef.current.focus()

    if (intellisenseConfig) {
      intellisenseConfig.dispose()
    }

    if (
      !localStorage.getItem('selectedMode') ||
      localStorage.getItem('selectedMode') === CONSTANTS.ARCHITECTURE_DIAGRAM
    ) {
      setIntellisense(monaco, 'INTELLISENSEJSON')
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.WEBSEQUENCE) {
      setIntellisense(monaco, 'WEBSEQ_INTELLISENSEJSON')
      setTimeout(generate_sequence_diagram, 1000, true)
    }

    setDashboardState()
    setIsRefUpdated(!isRefUpdated)
  }

  function setIntellisense(monaco, json) {
    const completionProvider = monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: () => {
        var intellisenseJson = localStorage.getItem(json)
        intellisenseJson = JSON.parse(intellisenseJson)
        return {
          suggestions: intellisenseJson,
        }
      },
    })
    setIntellisenseConfig(completionProvider)
  }

  // atlassian macro
  // Function to embed diagram
  const embedDiagram = () => {
    // Retrieve the diagram as a Base64 string from sessionStorage
    const diagram = sessionStorage.getItem("LAST_GENERATED_DIAGRAM");

    if (!diagram) {
      console.error("No diagram data found to embed!");
      return;
    }

    // Send the diagram data to the parent window (Forge app)
    window.parent.postMessage(
      { type: 'SAVE_MACRO', data: { diagram } },'*' // Allow any origin to send the message
    );
  };
  

  async function generate_sequence_diagram(isAutoExecute, code) {
    window.onerror = handleUncaughtError

    if (!code)
      code = monacoRef.current.getValue()
    if (!isAutoExecute) {
      setVisibleLoading(true)
      setTimeout(() => {
        setVisibleLoading(false)
        setOpenSnackbarDiagram(false)
      }, 2000)
    }

    if (!code || code === '' || /^[\n\t]+$/.test(code)) {
      setVisibleLoading(false)
      setOpenSnackbarDiagram(false)
      return
    }

    // if (sessionStorage.getItem('isSubscribed') !== 'true') {
    //   code = code + '\n text right: "code2diagram.com"'
    // }
    const outputImage = await generateWebSeq(
      code,
      setSnackbarMessage,
      setSnackbarSeverity,
      setOpenSnackbar,
      setOpenSnackbarDiagram,
    )
    if (outputImage) setImageSrc(outputImage)
  }

  function setDashboardState() {
    if (localStorage.getItem('isFirstVisit') !== 'true') {
      localStorage.setItem('isFirstVisit', 'true')
    }

    monacoRef.current.executeEdits('', [
      { range: monacoRef.current.getModel().getFullModelRange(), text: '' },
    ])
    if (localStorage.getItem('selectedMode') === CONSTANTS.WEBSEQUENCE) {
      dispatch(setSelectedMode(CONSTANTS.WEBSEQUENCE))
      if (localStorage.getItem('EDITORSTATE_WEB'))
        appendCode(localStorage.getItem('EDITORSTATE_WEB'))
      else appendCode('#title Foo\nA -> B\nB -> A')
      if (localStorage.getItem('LASTGENERATEDIMAGE_WEB'))
        setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE_WEB'))
      else
        setImageSrc(
          'https://res.cloudinary.com/djvyd8hea/image/upload/v1703245331/diagram/websequence/90250de8-f93a-47fb-ba81-c6e7e4351f30_srbc59.png',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.CLASS_DIAGRAM) {
      dispatch(setSelectedMode(CONSTANTS.CLASS_DIAGRAM))
      if (localStorage.getItem('class_diagram')) {
        appendCode(localStorage.getItem('class_diagram'))
      } else
        appendCode(
          ' Animal <-- Duck \n Animal <-- Fish \n Animal <--> Zebra \n Animal : int age \n Animal : String gender \n Animal: isMammal() \n Animal: mate() \n class Duck{  \n  String beakColor \n swim() \n quack() \n } \n class Fish {\n  int sizeInFeet \n  canEat() \n } \n class Zebra{ \n bool is_wild \n run() \n }',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.SWAGGER) {
      dispatch(setSelectedMode(CONSTANTS.SWAGGER))
      if (localStorage.getItem('EDITORSTATE_SWAGGER')) {
        appendCode(localStorage.getItem('EDITORSTATE_SWAGGER'))
      } else
        appendCode(
          '{\n\t"openapi":"3.0.1",\n\t"info":{\n\t\t"title":"Sample API",\n\t\t"description":"A simple API for demonstration purposes",\n\t\t"version":"1.0.0"\n\t},\n\t"paths":{\n\t\t"/hello":{\n\t\t\t"get":{\n\t\t\t\t"summary":"Get a friendly greeting",\n\t\t\t\t"responses":{\n\t\t\t\t\t"200":{\n\t\t\t\t\t\t"description":"Successful response",\n\t\t\t\t\t\t"content":{\n\t\t\t\t\t\t\t"application/json":{\n\t\t\t\t\t\t\t\t"example":{\n\t\t\t\t\t\t\t\t\t"message":"Hello, Swagger!"\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}',
        )

      if (localStorage.getItem('LASTGENERATEDIMAGE_SWAGGER'))
        setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE_SWAGGER'))
      else
        setImageSrc(
          'https://res.cloudinary.com/dpqbidfvh/image/upload/v1708432119/Diagram_4_t4dald.svg',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.JSON) {
      dispatch(setSelectedMode(CONSTANTS.JSON))
      if (localStorage.getItem('EDITORSTATE_JSON')) {
        appendCode(localStorage.getItem('EDITORSTATE_JSON'))
      } else appendCode('{\n\t"fruit":"Apple",\n\t"size":"Large",\n\t"color": ["Red", "Green"]\n}')

      if (localStorage.getItem('LASTGENERATEDIMAGE_JSON'))
        setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE_JSON'))
      else
        setImageSrc(
          'https://res.cloudinary.com/dpqbidfvh/image/upload/v1708432119/Diagram_5_kupvxo.svg',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.YAML) {
      dispatch(setSelectedMode(CONSTANTS.YAML))
      if (localStorage.getItem('EDITORSTATE_YAML')) {
        appendCode(localStorage.getItem('EDITORSTATE_YAML'))
      } else appendCode('fruit: Apple\nsize: Large\ncolor:\n- Red\n- Green')

      if (localStorage.getItem('LASTGENERATEDIMAGE_YAML'))
        setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE_YAML'))
      else
        setImageSrc(
          'https://res.cloudinary.com/dpqbidfvh/image/upload/v1708432119/Diagram_5_kupvxo.svg',
        )
      } else if (localStorage.getItem('selectedMode') === CONSTANTS.ASCII) {
        dispatch(setSelectedMode(CONSTANTS.ASCII))
        if (localStorage.getItem('EDITORSTATE_ASCII')) {
          appendCode(localStorage.getItem('EDITORSTATE_ASCII'))
        } else appendCode('A --> B & C')
  
        if (localStorage.getItem('LASTGENERATEDIMAGE_ASCII'))
          setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE_ASCII'))
        else
          setImageSrc(
            'https://res.cloudinary.com/dpqbidfvh/image/upload/v1708432119/Diagram_5_kupvxo.svg',
          )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.GANTT_CHART) {
      dispatch(setSelectedMode(CONSTANTS.GANTT_CHART))
      if (localStorage.getItem('gantt_diagram')) {
        appendCode(localStorage.getItem('gantt_diagram'))
      } else
        appendCode(
          '[ \n{ \n  "start": "jan 5 2024", \n "end": "jun 5 2024", \n  "name": "Project", \n "id": "ProjectSample", \n "progress": 67, \n  "type": "project", \n  "hideChildren": false\n }, \n{ \n "start": "jan 5 2024", \n "end": "mar 13 2024", \n "name": "Idea 1", \n "id": "Task 0", \n "progress": 45, \n "type": "task", \n "project": "ProjectSample"\n },\n { \n "start": "feb 14 2024", \n "end": "may 13 2024", \n "name": "Idea 2", \n "id": "Task 1", \n "progress": 45, \n "type": "task", \n "project": "ProjectSample"\n }\n]',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.BRAINSTORM) {
      dispatch(setSelectedMode(CONSTANTS.BRAINSTORM))
      if (localStorage.getItem('brainstorm_diagram')) {
        appendCode(localStorage.getItem('brainstorm_diagram'))
      } else appendCode('a\n    b\n        g\n    c\n        d\n        e\n\nb -> e\ng -> d')
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.D2) {
      dispatch(setSelectedMode(CONSTANTS.D2))
      if (localStorage.getItem('d2_diagram')) {
        appendCode(localStorage.getItem('d2_diagram'))
      } else
        appendCode(
          'vars: {\n  d2-config: {\n    layout-engine: elk\n    # Terminal theme code\n    theme-id: 300\n  }\n}\nnetwork: {\n  cell tower: {\n    satellites: {\n      shape: stored_data\n      style.multiple: true\n    }\n\n    transmitter\n\n    satellites -> transmitter: send\n    satellites -> transmitter: send\n    satellites -> transmitter: send\n  }\n\n  online portal: {\n    ui: {shape: hexagon}\n  }\n\n  data processor: {\n    storage: {\n      shape: cylinder\n      style.multiple: true\n    }\n  }\n\n  cell tower.transmitter -> data processor.storage: phone logs\n}\n\nuser: {\n  shape: person\n  width: 130\n}\n\nuser -> network.cell tower: make call\nuser -> network.online portal.ui: access {\n  style.stroke-dash: 3\n}\n\napi server -> network.online portal.ui: display\napi server -> logs: persist\nlogs: {shape: page; style.multiple: true}\n\nnetwork.data processor -> api server',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.PROTOBUF) {
      dispatch(setSelectedMode(CONSTANTS.PROTOBUF))
      if (localStorage.getItem('protobuf_diagram')) {
        appendCode(localStorage.getItem('protobuf_diagram'))
      } else
        appendCode('message Person {\n string name = 1;\n int32 id = 2; \n string email = 3;\n }')
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.PLAYBOOK_GRAPHER) {
      dispatch(setSelectedMode(CONSTANTS.PLAYBOOK_GRAPHER))
      if (localStorage.getItem('playbookGrapher_diagram')) {
        appendCode(localStorage.getItem('playbookGrapher_diagram'))
      } else
        appendCode(
          '- hosts: all\n  vars:\n    backport: "stretch-backports"\n    packages:\n      - git\n      - tree\n      - curl\n  pre_tasks:\n    - name: Pre task 1\n      debug: msg="Pretask"\n    - name: Pre task 2\n      debug: msg="Pretask"\n\n  tasks:\n    - name: Add the backbport\n      become: yes\n      apt_repository:\n        repo: deb http://ftp.debian.org/debian {{backport}} main\n        filename: "{{backport}}"\n        state: present\n\n    - name: Install packages\n      become: yes\n      apt:\n        name: "{{packages}}"\n        state: present\n        default_release: "{{backport}}"\n\n    - name: Command that should fails\n      command: /bin/false\n      ignore_errors: true\n\n    - name: This task has "double quotes" in the name\n      command: /bin/true\n\n  post_tasks:\n    - name: Post task\n      debug: msg="Post task 1"\n\n    # We use the same name here just to test if we have two distinct nodes in the graph\n    - name: Post task\n      debug: msg="Post task"',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.INVENTORY_GRAPHER) {
      dispatch(setSelectedMode(CONSTANTS.INVENTORY_GRAPHER))
      if (localStorage.getItem('inventoryGrapher_diagram')) {
        appendCode(localStorage.getItem('inventoryGrapher_diagram'))
      } else
        appendCode(
          '[web]\nweb1.example.com ansible_host=192.168.1.101 ansible_user=admin\nweb2.example.com ansible_host=192.168.1.102 ansible_user=admin\n\n[database]\ndb1.example.com ansible_host=192.168.1.103 ansible_user=admin\n\n[app:children]\nweb\ndatabase',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.MATHEQUATION) {
      dispatch(setSelectedMode(CONSTANTS.MATHEQUATION))
      if (localStorage.getItem('mathEq_diagram')) {
        appendCode(localStorage.getItem('mathEq_diagram'))
      } else
        appendCode(
          `xAxis:\n  label: 'time'\n  domain: [-6, 6]\nyAxis:\n  label: 'growth'\ndata:\n - fn: '3 + sin(x)'`,
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.TERRAFORM) {
      dispatch(setSelectedMode(CONSTANTS.TERRAFORM))
      if (localStorage.getItem('terraform_diagram')) {
        appendCode(localStorage.getItem('terraform_diagram'))
      } else
        appendCode(
          'provider "aws" {\n  region = "us-west-2"\n}\n\nresource "aws_instance" "example" {\n  ami           = "ami-0c94855ba95c574c8"\n  instance_type = "t2.micro"\n\n  tags = {\n    Name = "example-instance"\n  }\n}',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.DOCKERCOMPOSE) {
      dispatch(setSelectedMode(CONSTANTS.DOCKERCOMPOSE))
      if (localStorage.getItem('dockerCompose_diagram')) {
        appendCode(localStorage.getItem('dockerCompose_diagram'))
      } else
        appendCode(
          'services:\n  web:\n    image: nginx:latest\n    ports:\n      - "80:80"\n  database:\n    image: postgres:latest\n    environment:\n      POSTGRES_DB: mydatabase\n      POSTGRES_USER: myuser\n      POSTGRES_PASSWORD: mypassword',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.ARCHGO) {
      dispatch(setSelectedMode(CONSTANTS.ARCHGO))
      if (localStorage.getItem('archGo_diagram')) {
        appendCode(localStorage.getItem('archGo_diagram'))
      } else
        appendCode(
          'dns := gcp.Network.Dns(diagram.NodeLabel("DNS"))\nlb := gcp.Network.LoadBalancing(diagram.NodeLabel("NLB"))\ncache := gcp.Database.Memorystore(diagram.NodeLabel("Cache"))\ndb := gcp.Database.Sql(diagram.NodeLabel("Database"))\n\n\ndc := diagram.NewGroup("GCP")\ndc.NewGroup("services").\n    Label("Service Layer").\n    Add(\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 1")),\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 2")),\n        gcp.Compute.ComputeEngine(diagram.NodeLabel("Server 3")),\n    ).\n    ConnectAllFrom(lb.ID(), diagram.Forward()).\n    ConnectAllTo(cache.ID(), diagram.Forward())\n\ndc.NewGroup("data").Label("Data Layer").Add(cache, db).Connect(cache, db)\n\nd.Connect(dns, lb, diagram.Forward()).Group(dc)',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.AVROSCHEMA) {
      dispatch(setSelectedMode(CONSTANTS.AVROSCHEMA))
      if (localStorage.getItem('avroSchema_diagram')) {
        appendCode(localStorage.getItem('avroSchema_diagram'))
      } else
        appendCode(
          '{ \n "type": "record",\n "name": "User",\n "fields": [\n {"name": "id", "type": "int"},\n {"name": "username", "type": "string"},\n {"name": "email", "type": "string"}\n ]\n}',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.MINDMAP) {
      dispatch(setSelectedMode(CONSTANTS.MINDMAP))
      if (localStorage.getItem('mindmap_diagram')) {
        appendCode(localStorage.getItem('mindmap_diagram'))
      } else
        appendCode(
          'Legacy Updates\n    Master\n        Doe\n        Smith\n    epic-1234\n        Johnson\n            Clark\n        Brown\n    Pontet\n        White\n        Miller',
        )
    } else if (localStorage.getItem('selectedMode') === CONSTANTS.ARCHITECTURE_DIAGRAM) {
      dispatch(setSelectedMode(CONSTANTS.ARCHITECTURE_DIAGRAM))
      if (localStorage.getItem('EDITORSTATE')) appendCode(localStorage.getItem('EDITORSTATE'))
      else
        appendCode(
          '#Node is an abstract concept that represents a single system component object. In below example Team is a node\nTeams("Teams")\n\n#Bundle allows you group (or clustering) the nodes in an isolated group. In below example ELB and EC2 are bundled.\nBundle("DB Cluster"): ELB("lb") >> EC2("web")\n\n#Edge is an object representing a connection between Nodes with some additional properties. In below example >> are edge.\nawsUser("User") >> Teams("Teams")',
        )
      if (
        sessionStorage.getItem('SELECTEDFILE') &&
        sessionStorage.getItem('SELECTEDFILE') !== 'DEFAULTFILENAME'
      ) {
        localStorage.setItem('filename', sessionStorage.getItem('SELECTEDFILE'))
      }

      if (localStorage.getItem('LASTGENERATEDIMAGE'))
        setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE'))
      else
        setImageSrc(
          'https://res.cloudinary.com/djvyd8hea/image/upload/v1706165488/DefaultArchDiagram_ncic91.png',
        )
    } else {
      dispatch(setSelectedMode(CONSTANTS.FLOW_CHART))
      if (localStorage.getItem('flowchart_diagram')) {
        appendCode(localStorage.getItem('flowchart_diagram'))
      } else
        appendCode(
          'Start - Decision \n Decision -  Process1 : yes \n Decision - Process2 : no \n Process1 - Process3 \n Process3 - Process4 : Yes \n Process3 - Process5 : No \n Process4 - End \n Process5 - End',
        )
    }
    sessionStorage.setItem('PAUSEEDITORCHANGE', 'false')
  }

  function handleUncaughtError(message, source, lineno, colno, error) {
    setPythonError(message + 'at line' + lineno + 'column' + colno)
    setSnackbarMessage(pythonError)
    setSnackbarSeverity('error')
    setOpenSnackbar(true)
  }

  function selectedCodeFile(option) {
    handleOptionChange(option)
    localStorage.setItem('SELECTEDFILE', option)
    if (option !== CONSTANTS.DEFAULTFILENAME) selectedCode(codeByFileName[option])
    else selectedCode(null)
  }

  // const saveFileToDisk = (filename) => {
  //   if (!monacoRef.current) return;

  //   filename = prompt("Enter file name:", "file.txt");
  //   if(!filename) return;

  //   // Get the content from the editor
  //   const content = monacoRef.current.getValue();

  //   // Create a blob with the content
  //   const blob = new Blob([content], { type: "text/plain" });

  //   // Create a download link
  //   const a = document.createElement("a");
  //   a.href = URL.createObjectURL(blob);
  //   a.download = filename; // Set default file name
  //   a.click();

  //   // Release URL object
  //   URL.revokeObjectURL(a.href);
  // };

  const handleDiskFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      if (monacoRef.current) {
        monacoRef.current.setValue(fileContent); // Set the content to the Monaco editor
      }
    };
    reader.readAsText(file); // Read the file as text

    setFileName(file.name.split('.')[0]);
    setFileSelected(true);
  };

  function manageSave() {
    if (!isAuthenticated) {
      setSnackbarMessage('Please login to save')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    var editorValue = monacoRef.current.getValue()
    if (!editorValue || editorValue === '' || /^[\n\t]+$/.test(editorValue)) {
      setSnackbarMessage('Empty Editor. Nothing to save!!!')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    var selectedFileName = localStorage.getItem('SELECTEDFILE')
    if (!selectedFileName || selectedFileName === CONSTANTS.DEFAULTFILENAME) {
      setVisibleSave(true)
    } else {
      saveCodeApi(selectedOptionAuto, 'UPDATE')
    }
  }

  function saveCodeState(value, event) {
    if (sessionStorage.getItem('PAUSEEDITORCHANGE') === 'true') return

    var editorValue = monacoRef.current.getValue()
    if (editorValue) localStorage.setItem("EDITOR_VALUE", editorValue);

    if (selectedMode === CONSTANTS.FLOW_CHART) {
      dispatch(setEditorValue(value))
      const localStorageKey = 'flowchart_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.CLASS_DIAGRAM) {
      dispatch(setClassEditorValue(value))
      const localStorageKey = 'class_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.GANTT_CHART) {
      dispatch(setGanttEditorValue(value))
      const localStorageKey = 'gantt_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.BRAINSTORM) {
      dispatch(setBrainstormEditorValue(value))
      const localStorageKey = 'brainstorm_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.D2) {
      dispatch(setD2EditorValue(value))
      const localStorageKey = 'd2_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.PROTOBUF) {
      dispatch(setProtobufEditorValue(value))
      const localStorageKey = 'protobuf_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.PLAYBOOK_GRAPHER) {
      dispatch(setPlaybookGrapherEditorValue(value))
      const localStorageKey = 'playbookGrapher_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.INVENTORY_GRAPHER) {
      dispatch(setInventoryGrapherEditorValue(value))
      const localStorageKey = 'inventoryGrapher_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.MATHEQUATION) {
      dispatch(setMathEqEditorValue(value))
      const localStorageKey = 'mathEq_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.TERRAFORM) {
      dispatch(setTerraformEditorValue(value))
      const localStorageKey = 'terraform_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.DOCKERCOMPOSE) {
      dispatch(setDockerComposeEditorValue(value))
      const localStorageKey = 'dockerCompose_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.ARCHGO) {
      dispatch(setArchGoEditorValue(value))
      const localStorageKey = 'archGo_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.AVROSCHEMA) {
      dispatch(setAvroSchemaEditorValue(value))
      const localStorageKey = 'avroSchema_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (selectedMode === CONSTANTS.MINDMAP) {
      dispatch(setMindmapEditorValue(value))
      const localStorageKey = 'mindmap_diagram'
      localStorage.setItem(localStorageKey, editorValue)
    }
    if (
      localStorage.getItem('selectedMode') === CONSTANTS.WEBSEQUENCE
    ) {
      if (editorValue &&
        editorValue !== '') {
        localStorage.setItem('EDITORSTATE_WEB', editorValue)
        generate_sequence_diagram(true)
      } else {
        setImageSrc("");
      }

    } else if (localStorage.getItem('selectedMode') === CONSTANTS.ARCHITECTURE_DIAGRAM) {
      dispatch(setArchitectureEditorValue(value))
      localStorage.setItem('EDITORSTATE', editorValue)
    }
  }

  function handleSelectedMode(mode) {
    dispatch(setSelectedMode(mode))
    localStorage.setItem('selectedMode', mode)
    localStorage.setItem('SELECTEDFILE', CONSTANTS.DEFAULTFILENAME)
    const selectevent = new CustomEvent('selectedMode', { detail: mode })
    document.dispatchEvent(selectevent)
  }
  const onChangeSelect = (value) => {
    selectedCodeFile(value)
  }



  return (
    <>
      <div className="editor-menu" style={{ backgroundColor: '#1e1e1e' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <Button
            className="expand-button-editor"
            onClick={() => {
              dispatch(sidebartoggle())
              setVisibleArrow(!visibleArrow)
            }}
          >
            {visibleArrow ? (
              <svg
                width="6"
                height="24"
                viewBox="0 0 6 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.27758 1.66546C4.1174 0.704397 3.28589 0 2.31157 0V0C1.094 0 0.160976 1.0821 0.340133 2.28641L1.6976 11.4114C1.75565 11.8017 1.75565 12.1983 1.6976 12.5886L0.340133 21.7136C0.160976 22.9179 1.094 24 2.31157 24V24C3.28589 24 4.1174 23.2956 4.27758 22.3345L5.8904 12.6576C5.96297 12.2222 5.96297 11.7778 5.8904 11.3424L4.27758 1.66546Z"
                  fill="#878787"
                />
              </svg>
            ) : (
              <svg width="6" height="24" viewBox="0 0 6 24" fill="none">
                <path
                  d="M1.72242 1.66546C1.8826 0.704397 2.71411 0 3.68843 0C4.906 0 5.83902 1.0821 5.65987 2.28641L4.3024 11.4114C4.24435 11.8017 4.24435 12.1983 4.3024 12.5886L5.65987 21.7136C5.83902 22.9179 4.906 24 3.68843 24C2.71411 24 1.8826 23.2956 1.72242 22.3345L0.109599 12.6576C0.0370336 12.2222 0.0370336 11.7778 0.109599 11.3424L1.72242 1.66546Z"
                  fill="#878787"
                />
              </svg>
            )}
          </Button>
          <CustomDropdown
            setSelectedMode={setSelectedMode}
            dispatch={dispatch}
            handleSelectedMode={handleSelectedMode}
            history={history}
            onSelectFile={onChangeSelect}
            codeByFileName={codeByFileName}
            selectedOptionAuto={selectedOptionAuto}
            handleDiskFileChange={handleDiskFileChange}
            fileName = {fileName}
            fileSelected={ fileSelected}
          />
        </div>

        <ChatGPT onResponse={onChatGPTResponse}
           isAuthenticated={ isAuthenticated}
           setSnackbarSeverity={setSnackbarSeverity}
           setOpenSnackbar={ setOpenSnackbar}
           setSnackbarMessage={setSnackbarMessage}
        />

    {!isIframe && (
        <CollaborativeSession
          monacoRef={monacoRef}
          clientSelectedMode={selectedMode}
          handleSelectedMode={handleSelectedMode}
          direction={direction}
          setDirection={setDirection}
          isAuthenticated={ isAuthenticated}
          setSnackbarSeverity={setSnackbarSeverity}
          setOpenSnackbar={ setOpenSnackbar}
          setSnackbarMessage={setSnackbarMessage}
        />
    )}


        
        <div style={{ display: 'flex', gap: '5px' }}>

        { isIframe && (
          <Button
            className='icon-button'
            variant="contained"
            onClick={() => {
              embedDiagram()
            }}
          >
            Embed
          </Button>
        )}

        {!isIframe && (
          <Tooltip title="Save">
            <Button
              className="icon-button"
              onClick={() => {
                +
                manageSave()
                // saveFileToDisk()
              }}
            >
              <SaveAsIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}

          {localStorage.getItem('SELECTEDFILE') && localStorage.getItem('SELECTEDFILE') !== CONSTANTS.DEFAULTFILENAME && (
            <Tooltip title="Delete File">
              <Button
                className="icon-button"
                onClick={() => {
                  setShowDeleteConfirmation(true)
                }}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}

          <AvscModal
            dispatch={dispatch}
            setShowUploadModal={setShowUploadModal}
            showUploadModal={showUploadModal}
          />

          {selectedMode && selectedMode === CONSTANTS.AVROSCHEMA && (
            <Tooltip title="Upload AVSC">
              <Button className="icon-button" onClick={() => setShowUploadModal(true)} size="small">
                <FileUploadIcon fontSize="small" />
                AVSC
              </Button>
            </Tooltip>
          )}
          {/* {selectedMode &&
            (
              selectedMode === CONSTANTS.SWAGGER) && (
              <Tooltip title="Execute Code">
                <Button
                  className="run-button"
                  style={{ color: '#fff', height: '35px' }}
                  onClick={() => {
                    handleExecute(false)
                  }}
                >
                  Run
                </Button>
              </Tooltip>
            )} */}

          <Snackbar
            open={isDialogOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleClose} severity="warning" variant="filled">
              The editor is empty. Please enter some code to execute.
            </Alert>
          </Snackbar>

          <UndoRedoButtons handleUndo={handleUndo} handleRedo={handleRedo} />


        </div>
      </div>
      <Editor
        key={selectedMode}
        className="editor-class"
        height="calc(90vh)"
        theme="vs-dark"
        language="python"
        defaultValue={editorState}
        onMount={handleEditorDidMount}
        onChange={saveCodeState}
        options={{
          wordWrap: 'on', wordWrapColumn: 60, minimap: { enabled: false }, suggest: {
            // Positioning options
            showIcons: false, // Optional: Display icons for completion items (default: true)
          }
        }}
      />
    </>
  )
}

export default EditorSide
