import React, { useEffect, useState } from 'react'
import { Select, MenuItem, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { CONSTANTS } from '../Constants'
import { useSelector } from 'react-redux'

const CustomDropdown = ({
  setSelectedMode,
  dispatch,
  handleSelectedMode,
  history,
  onSelectFile,
  codeByFileName,
  selectedOptionAuto,
  handleDiskFileChange,
  fileSelected,
  fileName
}) => {
  const [searchText, setSearchText] = useState('')
  const selectedMode = useSelector((state) => state.stateReducer.selectModeValue) || ''
  const [selectedOption, setSelectedAuto] = useState(selectedOptionAuto || CONSTANTS.DEFAULTFILENAME)

  const updateKeyValuePair = (key, value) => {
    var selectedOptionByMode= localStorage.getItem("selectedOptionByMode")
    if(!selectedOptionByMode){
      let json={} 
      json[key]= value
      localStorage.setItem("selectedOptionByMode", JSON.stringify(json))
    }else{
      let json= JSON.parse(selectedOptionByMode)
      json[key]= value
      localStorage.setItem("selectedOptionByMode", JSON.stringify(json))
    }
  };

  const [isDiskFileSelected, setDiskFileSelected] = useState(false);

  const onDiskFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFileName = file.name;
      handleDiskFileChange(event);
  
     
      // if (!history.includes(newFileName)) {
     
      //   history.push(newFileName);
    
      setSelectedAuto(newFileName);
      setDiskFileSelected(true);
    }
  };

  useEffect(() => {
    if(selectedOptionAuto !== CONSTANTS.DEFAULTFILENAME || sessionStorage.getItem("FILE_DELETED")==="TRUE"){
      if(sessionStorage.getItem("FILE_DELETED")) sessionStorage.removeItem("FILE_DELETED")
      handleFileChange(selectedOptionAuto)
    }
  }, [selectedOptionAuto])

  useEffect(() => {
    localStorage.setItem("SELECTEDFILE", CONSTANTS.DEFAULTFILENAME)
    var optionByMode= localStorage.getItem("selectedOptionByMode")
    if(optionByMode){ 
      let json= JSON.parse(optionByMode)
      if(json[selectedMode]){ setSelectedAuto(json[selectedMode]) 
        localStorage.setItem("SELECTEDFILE", json[selectedMode]) }
      else setSelectedAuto(CONSTANTS.DEFAULTFILENAME)
    }else if (!selectedOption || !history.includes(selectedOption)) {
      setSelectedAuto(CONSTANTS.DEFAULTFILENAME)
    }else{
      setSelectedAuto(CONSTANTS.DEFAULTFILENAME)
    }
  }, [selectedMode])

  function handleFileChange(option){
    setSelectedAuto(option);
    updateKeyValuePair(selectedMode, option)
    localStorage.setItem("SELECTEDFILE", option)
  }

  const displayedOptions = [
    { name: 'Architecture Diagram', value: CONSTANTS.ARCHITECTURE_DIAGRAM },
    { name: 'Architecture Go', value: CONSTANTS.ARCHGO },
    { name: 'Web Sequence', value: CONSTANTS.WEBSEQUENCE },
    { name: 'Flow Chart', value: CONSTANTS.FLOW_CHART },
    { name: 'OpenAI/Swagger To UML', value: CONSTANTS.SWAGGER },
    { name: 'JSON To UML', value: CONSTANTS.JSON },
    { name: 'YAML To UML', value: CONSTANTS.YAML },
    { name: 'Class Diagram', value: CONSTANTS.CLASS_DIAGRAM },
    { name: 'D2 diagram', value: CONSTANTS.D2 },
    { name: 'Protobuf to UML', value: CONSTANTS.PROTOBUF },
    { name: 'Avro to UML', value: CONSTANTS.AVROSCHEMA },
    { name: 'Docker Compose Diagram', value: CONSTANTS.DOCKERCOMPOSE },
    { name: 'Terraform', value: CONSTANTS.TERRAFORM },
    { name: 'Brainstorming', value: CONSTANTS.BRAINSTORM },
    { name: 'MindMap', value: CONSTANTS.MINDMAP },
    { name: 'Math Equation Diagram', value: CONSTANTS.MATHEQUATION },
    { name: 'UML to ASCII', value: CONSTANTS.ASCII },
    { name: 'Playbook Grapher', value: CONSTANTS.PLAYBOOK_GRAPHER },
    { name: 'Gantt Chart', value: CONSTANTS.GANTT_CHART, disabled: true },
    { name: 'Inventory Grapher', value: CONSTANTS.INVENTORY_GRAPHER },
  ]

  const getOptions = () => {
    if (searchText) {
      return displayedOptions.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase()),
      )
    }
    return displayedOptions
  }

  return (
    <>
      <Select
        MenuProps={{ autoFocus: false }}
        className="icon-button diagram-list"
        style={{ width: '190px', border: '#183d3d' }}
        value={selectedMode}
        label="Options"
        size="small"
        onChange={(e) => {
          const value = e.target.value
          dispatch(setSelectedMode(value))
          localStorage.setItem('selectedMode', value)
          handleSelectedMode(value)
          setSelectedAuto(CONSTANTS.DEFAULTFILENAME)
          setSearchText('')
        }}
      >
        <TextField
          size="small"
          autoFocus
          placeholder="Search Diagram"
          fullWidth
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            inputProps: {
              style: { color: '#fff' },
            },
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
        {getOptions().map((option, i) => (
          <MenuItem key={i} value={option.value} className="mode_menu" disabled={option.disabled}>
            {option.name}
          </MenuItem>
        ))}
      </Select>

      <Select
        className="icon-button"
        size="small"
        value={isDiskFileSelected ? fileName : selectedOption}
        style={{ width: '170px', borderRadius: '3px' }}
        onChange={(e) => {
          const value = e.target.value
          setDiskFileSelected(false);
          handleFileChange(value)
          onSelectFile(value)
        }}
      >
        <MenuItem value={CONSTANTS.DEFAULTFILENAME}>Untitled (New File)</MenuItem>
        
        {history?.map((fileName, index) => {
          const fileDetails = codeByFileName[fileName]
          
          if (
            !fileDetails ||
            fileDetails.mode === selectedMode ||
            (selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM && !fileDetails.mode)
          ) {
            return (
              
              <MenuItem key={index} value={fileName}>
                {fileName}
               
              </MenuItem>
              
            )
            
          }
          
          return null
        })}

        
  {isDiskFileSelected && !history.includes(fileName) && (
    <MenuItem value={fileName}>{fileName}</MenuItem>
  )}
  
 
  <div>
  <p className="disk">Select from disk</p>
</div>
<div className="file-upload-container">
  <input
    type="file"
    accept=".txt"
    style={{ display: 'none' }}
    onChange={onDiskFileSelect}
    id="fileInput"
    className="file-input"
  />
  <button
    onClick={() => document.getElementById('fileInput').click()}
    className="icon-button disk-submit"
  >
    Choose file
  </button>
</div>

      </Select>
    </>
  )
}

export default CustomDropdown
