import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import { MonacoBinding } from 'y-monaco';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Snackbar, Alert, Checkbox, List, ListItem, ListItemText, FormControlLabel, Switch, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Person2Icon from '@mui/icons-material/Person2';
import { CONSTANTS } from '../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMode } from 'src/redux/actions/StateAction'


const CollaborativeSession = ({ monacoRef, handleSelectedMode, clientSelectedMode, direction, setDirection, isAuthenticated, setSnackbarSeverity, setOpenSnackbar, setSnackbarMessage}) => {
  const [channel, setChannel] = useState('');
  const [activeChannel, setActiveChannel] = useState(null);
  const ydoc = useMemo(() => new Y.Doc(), []);
  const [provider, setProvider] = useState(null);
  const [binding, setBinding] = useState(null);
  const [c2dSyncProvider, setC2dSyncProvider] = useState(null);

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [sessionID, setSessionID] = useState('');
  const [inputID, setInputID] = useState('');
  const [userName, setUserName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage1] = useState('');

  const [membersOpen, setMembersOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [readOnly, setReadOnly] = useState(false);
  const ws = useRef(null);
  const selectedMode = useSelector((state) => state.stateReducer.selectModeValue) || ''
  const [currentUserID, setCurrentUserID] = useState(null);
  const [sessionStarterID, setSessionStarterID] = useState(null);
  const prevDirection = useRef(direction); // Reference to store the previous direction
  const [isConnected, setIsConnected] = useState(false);
 


  useEffect(() => {
    if (isConnected && ws.current && direction !== prevDirection.current) {
      ws.current.send("C2D_ORIENTATION_" + direction);
      prevDirection.current = direction; // Update the previous direction
    }
  }, [direction, isConnected]);

  useEffect(() => {
    setSelectedMode(clientSelectedMode);
  }, [clientSelectedMode])

  const handleOpenModal = (type) => {
    if (!isAuthenticated) {
      setSnackbarMessage('Please login')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }
    if (type === 'start' || type === 'join') {
      if (!userName.trim()) {
        setSnackbarMessage('Please enter your name.')
        setSnackbarSeverity('warning')
        setOpenSnackbar(true)
        return;
      }
    }

    setModalType(type);
    setOpen(true);

    if (type === 'start') startSession();
  };

  const handleCloseModal = () => {
    setOpen(false);
    setUserName("")
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sessionID);
    setSnackbarMessage1('Session ID copied to clipboard!');
    setSnackbarOpen(true);
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMembersOpen = () => {
    getConnectionStatus(activeChannel);
    setMembersOpen(true);
  };

  const handleMembersClose = () => {
    setMembersOpen(false);
  };

  function wait(seconds) {
    return new Promise(resolve => setTimeout(resolve, seconds));
  }

  async function getConnectionStatus(id, isWait) {

    if (isWait) await wait(500);
    const resp = await axios.get(`${process.env.REACT_APP_COLLABORATIVE_SERVER}/channel-status/${id}`);
    if (!isWait) getConnectionStatus(id, true);
    if (resp.data) {
      const is_active = resp.data.active;
      const userNames = resp.data.usernames;
      const total_active_users = resp.data.usernames.length;
      setUserList(userNames);
      setTotalMembers(total_active_users);

      return resp.data;
    }

    return null;
  }

  useEffect(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ type: 'UPDATE_READONLY', readOnly }));
    }
  }, [readOnly]);


  async function startSession(channelID) {
    sessionStorage.setItem("IS_START", "TRUE");

    //update code 
    var editorValue = monacoRef.current.getValue()
    if (editorValue) localStorage.setItem("EDITOR_VALUE", editorValue);

    //TODO send diagramMode and readOnly to generateChannelID
    let config = {
      // diagramMode: CONSTANTS.WEBSEQUENCE,
      diagramMode: selectedMode || CONSTANTS.DEFAULTFILENAME,
      readOnly: readOnly,
    }
    if (channelID) config.channelID = channelID;
    if (direction) config.direction = "C2D_ORIENTATION_" + direction;

    channelID = await generateChannelID(config);
    if (config.channelID) getConnectionStatus(config.channelID);
    sessionStorage.setItem("CC_CHANNEL_SID", channelID);
    setActiveChannel(channelID);
    setSessionStarterID(userName); // Assuming 'userName' is the session starter
    setCurrentUserID(userName);
    ws.current = createWebSocketConnection(channelID);

    ws.current.onopen = () => {
      setIsConnected(true);
      handleWebSocketOpen(ws.current, channelID);
    };

    ws.current.onmessage = (event) => {
      handleWebSocketMessage(event, channelID);
    };

  }

  async function joinSession(channelID) {
    if (!channelID) channelID = inputID;
    sessionStorage.setItem("CC_CHANNEL_JID", channelID);

    let sessionStatusObj = await getConnectionStatus(channelID);
    setCurrentUserID(userName)
    if (!sessionStatusObj || !sessionStatusObj.active) {
      setSnackbarMessage1('Session is inactive.');
      setSnackbarOpen(true);
      // TODO show inactive session warning
      return
    }

    //TODO set diagram mode using sessionStatusObj.config.diagramMode

    // will set editor to read only
    const { config } = sessionStatusObj;
    console.log("sessionStatusObj", config);


    if (config && typeof config.readOnly !== 'undefined') {
      monacoRef.current.updateOptions({ readOnly: config.readOnly });
    }

    // if (config && config.diagramMode && typeof handleSelectedMode === 'function')
    if (config && config.diagramMode && handleSelectedMode) {
      // dispatch(setSelectedMode(config.diagramMode));
      handleSelectedMode(config.diagramMode);
    }

    ws.current = createWebSocketConnection(channelID);

    ws.current.onopen = () => {
      setIsConnected(true);
      handleWebSocketOpen(ws.current, channelID);

      //will sync diagram direction
      if (config.direction) setDirection(config.direction.replace("C2D_ORIENTATION_", ""))
    };

    ws.current.onmessage = (event) => {
      handleWebSocketMessage(event, channelID);
    };

  }

  async function generateChannelID(config) {
    try {
      const resp = await axios.post(`${process.env.REACT_APP_COLLABORATIVE_SERVER}/generateChannelID`, config);
      const channelID = resp.data;
      setSessionID(channelID);
      return channelID;
    } catch (error) {
      // console.error("Error generating channel ID:", error);
    }
  }

  function createWebSocketConnection(channelID) {
    const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SERVER}/${channelID}_C2D_SYNC`);
    setC2dSyncProvider(ws);
    return ws;
  }

  function handleWebSocketOpen(ws, channelID) {
    let username = userName;
    if (userName) {
      sessionStorage.setItem("CC_USERNAME", userName);
    } else {
      username = sessionStorage.getItem("CC_USERNAME");
    }
    ws.send(`34feb914c099df25794bf9ccb85bea72__${username}`);
    console.log('Username sent:', username);

    setChannel(channelID);
    setActiveChannel(channelID);
  }

  function handleWebSocketMessage(event, channelID) {
    try {
      if (!event.data) return;
      const serverMessage = event.data;

      if (typeof serverMessage === 'string') {
        let parsedMessage;
        try {
          parsedMessage = JSON.parse(serverMessage);
        } catch (error) {
          parsedMessage = serverMessage;
        }

        if (parsedMessage === "C2D_SYNC") {
          getConnectionStatus(channelID);
        } else if (parsedMessage === "C2D_SYNC_END") {
          alert("Session Ended By Admin")
          endSession();
        } else if (parsedMessage.includes("C2D_ORIENTATION_")) {
          let c2d_orientation = parsedMessage.replace("C2D_ORIENTATION_", "");
          setDirection(c2d_orientation)
        } else if (parsedMessage.type === 'UPDATE_DIAGRAM_MODE') {
          const { diagramMode } = parsedMessage;
          if (diagramMode) {
            console.log(`Updating diagram mode to: ${diagramMode}`);
          }
        } else {
          console.log("Received message:", parsedMessage);
        }
      }
    } catch (error) {
      console.error("Error processing WebSocket message:", error);
    }
  }

  const endSession = () => {

    monacoRef.current.updateOptions({ readOnly: false });

    if (sessionStorage.getItem("IS_START") === "TRUE") {
      ws.current.send("C2D_SYNC_END");
    }

    if (provider || sessionStorage.getItem("CC_CHANNEL_SID") || sessionStorage.getItem("CC_CHANNEL_JID")) {
      if (provider) {
        c2dSyncProvider.close();
        provider.destroy();
        ydoc.destroy();
      }
      setProvider(null);
      setBinding(null);
      setActiveChannel(null);
      setSnackbarMessage1('Session has ended');
      setSnackbarOpen(true);
      handleCloseModal();
      setUserName("");
      setInputID("");
      setReadOnly(false);
      sessionStorage.removeItem("IS_START")
      sessionStorage.removeItem("CC_CHANNEL_SID")
      sessionStorage.removeItem("CC_CHANNEL_JID")
    }
  };

  const handleModalAction = () => {
    if (modalType === 'join') {
      joinSession();
    } else if (modalType === 'end') {
      endSession();
    }
    setOpen(false);
  };

  async function refreshColabChannel() {
    if (!monacoRef.current) {
      await wait(1000);
      refreshColabChannel()
    } else if (sessionStorage.getItem("CC_CHANNEL_SID")) {
      setUserName(sessionStorage.getItem("CC_USERNAME"));
      startSession(sessionStorage.getItem("CC_CHANNEL_SID"));
    } else if (sessionStorage.getItem("CC_CHANNEL_JID")) {
      setUserName(sessionStorage.getItem("CC_USERNAME"));
      joinSession(sessionStorage.getItem("CC_CHANNEL_JID"));
    }
  }

  useEffect(() => {
    refreshColabChannel()
  }, []);

  useEffect(() => {
    if (activeChannel && monacoRef.current) {
      const newProvider = new WebsocketProvider(`${process.env.REACT_APP_WEBSOCKET_SERVER}`, activeChannel, ydoc);

      newProvider.on('status', ({ status }) => {
        console.log(`WebSocket status: ${status}`);
      });

      newProvider.on('sync', (isSynced) => {
        console.log(`Sync status: ${isSynced}`);
      });

      setProvider(newProvider);

      return () => {
        newProvider.destroy();
      };
    }
  }, [activeChannel, ydoc]);



  useEffect(() => {
    if (provider && monacoRef.current) {
      const yText = ydoc.getText('monaco');

      if (yText) {
        yText.delete(0, yText.length); // Delete all content in yText
      }

      // Set default text if the Yjs document is empty
      if (sessionStorage.getItem("IS_START") && localStorage.getItem("EDITOR_VALUE")) {
        yText.insert(0, localStorage.getItem("EDITOR_VALUE"));  // Set the default content
      } else {
        yText.insert(0, '');
      }

      const newBinding = new MonacoBinding(
        yText,
        monacoRef.current.getModel(),
        new Set([monacoRef.current]),
        provider.awareness
      );
      setBinding(newBinding);

      return () => {
        newBinding.destroy();
      };
    }
  }, [provider, monacoRef.current, ydoc]);

  return (
    <>


      {activeChannel && (
        <Button

          id='total-members'
          className='icon-button'
          variant="outlined"
          onClick={handleMembersOpen}
          disabled={!activeChannel}
          sx={{ ml: 1 }}
        >
          <Person2Icon /> {totalMembers}
        </Button>
      )}
      <Tooltip title='Collaborative coding'>
        <Button
          title='Collaborative coding'
          className='icon-button collaborative-coding'
          variant="contained"
          onClick={() => {
            if (activeChannel) {
              if (currentUserID === sessionStarterID) {
                endSession();
              } else {
                endSession();
              }
            } else {
              handleOpenModal('options');
            }
          }}
        >
          {activeChannel
            ? currentUserID === sessionStarterID ? 'End' : 'Leave'
            : 'CC'}
        </Button>
      </Tooltip>


      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle
          sx={{
            fontFamily: 'Poppins',
            fontSize: '24px',
            fontWeight: 500,
            textAlign: 'center',
            color: '#183D3D',
            padding: '16px',
          }}
        >
          Live Collaboration
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <p style={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          textAlign: 'center',
          margin: '0 20px',
        }}>
          Invite people to collaborate with Code2Diagram
        </p>
        <p style={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          textAlign: 'center',
          margin: '0 20px 20px',
        }}>
          Collaborate seamlessly on diagram creation, bring your team together with real-time updates and instant feedback!
        </p>
        <DialogContent>
          {modalType === 'options' && (
            <>

              <Tooltip title="The joiner can only read, cannot edit" placement="top">

                <FormControlLabel

                  control={

                    <Switch
                      checked={readOnly}
                      onChange={() => setReadOnly((prev) => !prev)}
                      color="success"
                      sx={{
                        '& .MuiSwitch-thumb': {
                          backgroundColor: readOnly ? '#4caf50' : '#9e9e9e',
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: readOnly ? '#81c784' : '#bdbdbd',
                        },
                        '&:hover': {
                          cursor: 'pointer',
                        },



                      }}
                    />
                  }
                  label="Read Only"
                  labelPlacement="start"
                  sx={{
                    margin: '10px'
                  }}
                />
              </Tooltip>


              <input type="text" name="fake" style={{ display: 'none' }} />


              <form autoComplete="off">
                <TextField
                  style={{ marginBottom: '20px' }}
                  className='cc-name'
                  placeholder="Enter Your Name"
                  variant="outlined"
                  fullWidth
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  name="ignoreName"
                  inputProps={{
                    autoComplete: 'off'
                  }}
                />

                <input type="text" name="fake" style={{ display: 'none' }} />
              </form>


              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button className="icon-button" onClick={() => handleOpenModal('start')} fullWidth variant="contained">
                    Start Session
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      if (!userName.trim()) {
                        setSnackbarMessage('Please enter your name')
                        setSnackbarSeverity('warning')
                        setOpenSnackbar(true)
                        return;
                      }
                      setModalType('join');
                      setOpen(true);
                    }}
                    fullWidth
                    variant="outlined"
                    sx={{
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        backgroundColor: '#D8D8D8',
                      }
                    }}
                  >
                    Join Session
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          {modalType === 'start' && (
            <>

              <TextField
                placeholder="Session ID"
                variant="outlined"
                fullWidth
                value={sessionID}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={copyToClipboard}>
                      <ContentCopyIcon />
                    </IconButton>
                  ),
                  readOnly: true,
                }}
                sx={{ mt: 2 }}
              />
            </>
          )}

          {modalType === 'join' && (
            <>

              <TextField
                placeholder="Enter Session ID"
                fullWidth
                value={inputID}
                onChange={(e) => setInputID(e.target.value)}
                sx={{ mt: 2 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions
          className="dialog-actions"
          sx={{
            backgroundColor: '#fff',
            padding: '16px',
          }}
        >
          {modalType !== 'options' && (
            <Button
              onClick={() => {
                if (modalType === 'join') {
                  handleModalAction();
                } else if (activeChannel) {
                  endSession();
                }
              }}
              variant="contained"
              className={modalType === 'join' ? 'join-session-button' : 'end-session-button'}
              sx={{
                backgroundColor: modalType === 'join' ? '#4CAF50' : '#F44336',
                color: 'white',
                '&:hover': {
                  backgroundColor: modalType === 'join' ? '#388E3C' : '#D32F2F',
                },
              }}
            >
              {modalType === 'join' ? 'Join Session' : 'End Session'}
            </Button>
          )}
        </DialogActions>

      </Dialog>

      <Dialog

        open={membersOpen}
        onClose={handleMembersClose}

        PaperProps={{
          sx: {
            background: 'linear-gradient(to bottom right, #183d3d, #000)',
            color: 'white',
            padding: '16px',
            borderRadius: '8px',
            minWidth: '300px'
          }
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleMembersClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px', fontFamily: 'Poppins' }}  >
          Active Members
        </DialogTitle>

        <DialogContent>
          <List sx={{ color: 'white' }}>
            {userList.map((user, index) => (
              <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Person2Icon sx={{ color: '#00e676', marginRight: '8px' }} />
                <ListItemText
                  primary={user}
                  primaryTypographyProps={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '500' }}
                />
              </ListItem>
            ))}
          </List>
          <p style={{ fontFamily: 'Poppins', fontSize: '16px', textAlign: 'center', marginTop: '10px' }}>
            Total Members: {totalMembers}
          </p>

        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className='icon-button'
            variant="contained"
            onClick={() => {
              if (activeChannel) {
                if (currentUserID === sessionStarterID) {
                  endSession();
                  handleMembersClose();
                } else {

                  endSession();
                  handleMembersClose();
                }
              } else {
                handleOpenModal('options');
              }
            }}
          >
            {activeChannel
              ? currentUserID === sessionStarterID ? 'End' : 'Leave'
              : 'CC'}
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          icon={false}
          variant="filled"
          onClose={handleSnackbarClose}
          sx={{
            width: '100%',
            backgroundColor: '#696969',
            color: 'white'
          }}
        >
          {snackbarMessage}
        </Alert>

      </Snackbar>

    </>
  );
};

export default CollaborativeSession;
